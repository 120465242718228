import './App.css';
import {CustomiseSection} from './components/CustomiseSection';
import TopBar from './components/TopBar';

const App = () => {
  return (
    <div>
      <TopBar />
      <CustomiseSection />
    </div>
  );
};

export default App;
