import React, {useState} from 'react';
import styles from './index.module.scss';
import {AiryChatPlugin, AiryChatPluginConfiguration} from '@airyhq/chat-plugin';
import {Button, Input, ListenOutsideClick} from '../general';
import {SketchPicker} from 'react-color';

export const CustomiseSection = () => {
  const [headerText, setHeaderText] = useState('');
  const [subtitleText, setSubtitleText] = useState('');
  const [startNewConversationText, setStartNewConversationText] = useState('');
  const [bubbleIconUrl, setBubbleIconUrl] = useState('');
  const [sendMessageIconUrl, setSendMessageIconUrl] = useState('');
  const [headerTextColor, setHeaderTextColor] = useState('');
  const [subtitleTextColor, setSubtitleTextColor] = useState('');
  const [showHeaderTextColorPicker, setShowHeaderTextColorPicker] = useState(false);
  const [showSubtitleTextColorPicker, setShowSubtitleTextColorPicker] = useState(false);
  const [primaryColor, setPrimaryColor] = useState('');
  const [showPrimaryColorPicker, setShowPrimaryColorPicker] = useState(false);
  const [accentColor, setAccentColor] = useState('');
  const [showAccentColorPicker, setShowAccentColorPicker] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('');
  const [showBackgroundColorPicker, setShowBackgroundColorPicker] = useState(false);

  const toggleShowHeaderTextColorPicker = () => {
    setShowHeaderTextColorPicker(!showHeaderTextColorPicker);
  };

  const toggleShowSubtitleTextColorPicker = () => {
    setShowSubtitleTextColorPicker(!showSubtitleTextColorPicker);
  };

  const toggleShowPrimaryColorPicker = () => {
    setShowPrimaryColorPicker(!showPrimaryColorPicker);
  };

  const toggleShowAccentColorPicker = () => {
    setShowAccentColorPicker(!showAccentColorPicker);
  };

  const toggleShowBackgroundColorPicker = () => {
    setShowBackgroundColorPicker(!showBackgroundColorPicker);
  };

  const demoConfig: AiryChatPluginConfiguration = {
    apiHost: 'https://demo.airy.co',
    channelId: 'b98e77bd-2c20-4836-9cb8-51562d947ab3',
    config: {
      showMode: false,
      ...(headerText && {headerText}),
      ...(subtitleText && {subtitleText}),
      ...(startNewConversationText && {startNewConversationText}),
      ...(headerTextColor && {headerTextColor}),
      ...(subtitleTextColor && {subtitleTextColor}),
      ...(primaryColor && {primaryColor}),
      ...(accentColor && {accentColor}),
      ...(backgroundColor && {backgroundColor}),
      ...(bubbleIconUrl && {bubbleIcon: bubbleIconUrl}),
      ...(sendMessageIconUrl && {sendMessageIcon: sendMessageIconUrl}),
    },
  };

  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <h1>Try and customize our Chatplugin</h1>
        <h2>Change the color and text, build your own chatplugin</h2>
      </div>
      <div className={styles.customizeContainer}>
        <div className={styles.inputsContainer}>
          <h4 className={styles.sectionTitle}>💬&nbsp;&nbsp;Customize the text:</h4>
          <Input
            type="text"
            name="textHeader"
            value={headerText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setHeaderText(e.target.value);
            }}
            label="Header Text"
            placeholder="Customer Chat"
            height={32}
            fontClass="font-base"
            maxLength={30}
          />
          <Input
            type="text"
            name="subtitle"
            value={subtitleText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSubtitleText(e.target.value);
            }}
            label="Subtitle Text"
            placeholder="Customer Subtitle"
            height={32}
            fontClass="font-base"
            maxLength={50}
          />
          <Input
            type="text"
            name="startNewConversationText"
            value={startNewConversationText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setStartNewConversationText(e.target.value);
            }}
            label="Start new Conversation Text"
            placeholder="Start a new conversation"
            height={32}
            fontClass="font-base"
          />
          <h4 className={styles.sectionTitle}>🔥&nbsp;&nbsp;Update the icons:</h4>
          <Input
            type="url"
            name="bubbleIconUrl"
            value={bubbleIconUrl}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setBubbleIconUrl(e.target.value);
            }}
            label="Chat Plugin Icon URL"
            placeholder="Add an image url"
            height={32}
            fontClass="font-base"
            showErrors={false}
          />
          <Input
            type="text"
            name="sendMessageIconUrl"
            value={sendMessageIconUrl}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSendMessageIconUrl(e.target.value);
            }}
            label="Input Icon URL"
            placeholder="Add an image url"
            height={32}
            fontClass="font-base"
            showErrors={false}
          />
        </div>
        <div className={styles.inputsContainer}>
          <h4 className={styles.sectionTitle}>✨&nbsp;&nbsp;Change the Colors</h4>
          <p>Header Text Color</p>
          <div className={styles.colorPicker}>
            {showHeaderTextColorPicker && (
              <ListenOutsideClick className={styles.colorPickerWrapper} onOuterClick={toggleShowHeaderTextColorPicker}>
                <SketchPicker
                  color={headerTextColor}
                  onChangeComplete={(color: {hex: string}) => {
                    setHeaderTextColor(color.hex.toUpperCase());
                  }}
                />
              </ListenOutsideClick>
            )}
            <div
              className={styles.colorPickerSample}
              style={{backgroundColor: headerTextColor}}
              onClick={toggleShowHeaderTextColorPicker}
            />
            <Input
              type="text"
              name="Header Text Color"
              value={headerTextColor}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setHeaderTextColor(e.target.value);
              }}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                if (value !== '') {
                  const newHeaderTextColor = value.startsWith('#') ? value : '#' + value;
                  setHeaderTextColor(newHeaderTextColor.toUpperCase());
                } else {
                  setHeaderTextColor('');
                }
              }}
              placeholder="#FFFFFF"
              height={32}
              fontClass="font-base"
            />
          </div>
          <p>Subtitle Text Color</p>
          <div className={styles.colorPicker}>
            {showSubtitleTextColorPicker && (
              <ListenOutsideClick
                className={styles.colorPickerWrapper}
                onOuterClick={toggleShowSubtitleTextColorPicker}
              >
                <SketchPicker
                  color={subtitleTextColor}
                  onChangeComplete={(color: {hex: string}) => {
                    setSubtitleTextColor(color.hex.toUpperCase());
                  }}
                />
              </ListenOutsideClick>
            )}
            <div
              className={styles.colorPickerSample}
              style={{backgroundColor: subtitleTextColor}}
              onClick={toggleShowSubtitleTextColorPicker}
            />
            <Input
              type="text"
              name="Subtitle Text Color"
              value={subtitleTextColor}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSubtitleTextColor(e.target.value);
              }}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                if (value !== '') {
                  const newSubtitleTextColor = value.startsWith('#') ? value : '#' + value;
                  setSubtitleTextColor(newSubtitleTextColor.toUpperCase());
                } else {
                  setSubtitleTextColor('');
                }
              }}
              placeholder="#FFFFFF"
              height={32}
              fontClass="font-base"
            />
          </div>
          <p>Primary Color</p>
          <div className={styles.colorPicker}>
            {showPrimaryColorPicker && (
              <ListenOutsideClick className={styles.colorPickerWrapper} onOuterClick={toggleShowPrimaryColorPicker}>
                <SketchPicker
                  color={primaryColor}
                  onChangeComplete={(color: {hex: string}) => {
                    setPrimaryColor(color.hex.toUpperCase());
                  }}
                />
              </ListenOutsideClick>
            )}
            <div
              className={styles.colorPickerSample}
              style={{backgroundColor: primaryColor}}
              onClick={toggleShowPrimaryColorPicker}
            />
            <Input
              type="text"
              name="Primary Color"
              value={primaryColor}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPrimaryColor(e.target.value);
              }}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                if (value !== '') {
                  const newPrimaryColor = value.startsWith('#') ? value : '#' + value;
                  setPrimaryColor(newPrimaryColor.toUpperCase());
                } else {
                  setPrimaryColor('');
                }
              }}
              placeholder="#FFFFFF"
              height={32}
              fontClass="font-base"
            />
          </div>
          <p>Accent Color</p>
          <div className={styles.colorPicker}>
            {showAccentColorPicker && (
              <ListenOutsideClick className={styles.colorPickerWrapper} onOuterClick={toggleShowAccentColorPicker}>
                <SketchPicker
                  color={accentColor}
                  onChangeComplete={(color: {hex: string}) => {
                    setAccentColor(color.hex.toUpperCase());
                  }}
                />
              </ListenOutsideClick>
            )}

            <div
              className={styles.colorPickerSample}
              style={{backgroundColor: accentColor}}
              onClick={toggleShowAccentColorPicker}
            />
            <Input
              type="text"
              name="accentColor"
              value={accentColor}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setAccentColor(e.target.value);
              }}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                if (value !== '') {
                  const newAccentColor = value.startsWith('#') ? value : '#' + value;
                  setAccentColor(newAccentColor.toUpperCase());
                } else {
                  setAccentColor('');
                }
              }}
              placeholder="#FFFFFF"
              height={32}
              fontClass="font-base"
            />
          </div>
          <p>Background Color</p>
          <div className={styles.colorPicker}>
            {showBackgroundColorPicker && (
              <ListenOutsideClick className={styles.colorPickerWrapper} onOuterClick={toggleShowBackgroundColorPicker}>
                <SketchPicker
                  color={backgroundColor}
                  onChangeComplete={(color: {hex: string}) => {
                    setBackgroundColor(color.hex.toUpperCase());
                  }}
                />
              </ListenOutsideClick>
            )}
            <div
              className={styles.colorPickerSample}
              style={{backgroundColor: backgroundColor}}
              onClick={toggleShowBackgroundColorPicker}
            />
            <Input
              type="text"
              name="backgroundColor"
              value={backgroundColor}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setBackgroundColor(e.target.value);
              }}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                if (value !== '') {
                  const newBackgroundColor = value.startsWith('#') ? value : '#' + value;
                  setBackgroundColor(newBackgroundColor.toUpperCase());
                } else {
                  setBackgroundColor('');
                }
              }}
              placeholder="#FFFFFF"
              height={32}
              fontClass="font-base"
            />
          </div>
        </div>
      </div>
      <div className={styles.mediaButtonsContainer}>
        <h4 className={styles.sectionTitle}>🚀&nbsp;&nbsp;Ready to get started?</h4>
        <div className={styles.installButton}>
          <Button
            styleVariant="outline-big"
            onClick={() => {
              window.open('https://www.airy.co/docs', '_blank');
            }}
          >
            Install Airy&nbsp;&nbsp;🚀
          </Button>
        </div>
        <div className={styles.repoButton}>
          <Button
            onClick={() => {
              window.open('https://www.github.com/airyhq/airy', '_blank');
            }}
          >
            Support Airy by giving us a Star&nbsp;⭐&nbsp;&nbsp;on Github
          </Button>
        </div>
      </div>
      <div className={styles.pluginWrapper}>
        <AiryChatPlugin config={demoConfig} />
      </div>
    </div>
  );
};
